// Theme Color

:root {
  // Primary
  --primary-main: #002C49; 
  --primary-alternative-light: #3A93D4; 
  --primary-alternative-dark: #246793; 
  --primary-contrast: #ffffff;
  --primary-background-items: #f0ffff;

  // Secondary
  --secondary-main: #8a8d93;
  --secondary-alternative-light: #F0F0F0;
  --secondary-alternative-medium: #D5D5D5;
  --secondary-alternative-dark: #4d5056;

  --highlight-main: #FF8D0D;

  // Info
  --info-main: #16b1ff;
  --info-alternative-light: #32baff;
  --info-alternative-dark: #0e71a3;

  // Success
  --success-main: #378100;
  --success-alternative-light: #6ad01f;
  --success-alternative-dark: #378100;

  // Warning
  --warning-main: #ffb400;
  --warning-alternative-light: #ffb547;
  --warning-alternative-dark: #a37300;
  --warning-background-alert: #fdede0;
  --warning-text-alert: #e6a200;

  // Error
  --error-main: #ff4c51;
  --error-alternative-light: #ff6166;
  --error-alternative-dark: #a33134;
  --error-background-alert: #fee8e7;
  --error-text-alert: #e64549;

  // Text
  --text-primary: rgba(58, 53, 65, 0.87); //#3A3541;
  --text-secondary: rgba(58, 53, 65, 0.68); // #3A3541;
  --text-disabled: rgba(58, 53, 65, 0.54); // #3A3541;
  --text-disabled: #3a3541;
  // Text Dark Mode
  --dark-text-primary: #ffffff;
  --dark-text-secondary: #e7e3fc;
  --dark-text-disabled: #ffffff;

  // Action
  --action-active: rgba(58, 53, 65, 0.54);
  --action-hover: rgba(58, 53, 65, 0.04);
  --action-selected: rgba(58, 53, 65, 0.08);
  --action-disabled: rgba(58, 53, 65, 0.26);
  --action-disabled-bg: rgba(58, 53, 65, 0.12);
  --action-disabled-focus: rgba(58, 53, 65, 0.12);

  // Other
  --divider: rgba(58, 53, 65, 0.12);
  --outline-border: rgba(58, 53, 65, 0.23);
  --input-line: rgba(58, 53, 65, 0.22);
  --overlay: rgba(58, 53, 65, 0.5);
  --snackbar-bg: #212121;
  --body-background: #f4f5fa;
  --body-background-dark: #292725;
  --paper-card: #ffffff;

  --mdc-outlined-text-field-disabled-label-text-color: #000000b6;
  --mat-select-disabled-trigger-text-color: #000;
  --mdc-outlined-text-field-disabled-input-text-color: #000;


  .dark-section {
    --input-line: rgba(168, 60, 60, 0.796) !important;
  }
}
