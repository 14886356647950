// Template de autenticação
// Pode ser usado nos fluxos de: Login, Cadastro, Recuperar senha
.auth-template {
  display: grid;
  grid-template-columns: 68.75% 31.25%;
  height: 100%;
  overflow: hidden;
}

.auth-background {
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 100%;
  }
}

.auth-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 30px;
  margin-left: 40px;
}

.auth-content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; // viewport-height
  background-color: var(--body-background-dark);

  .auth-logo {
    display: none;
  }
}

.auth-container {
  padding: 12px 28px;
  overflow-x: auto;
}

.auth-title {
  color: var(--primary-contrast);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.auth-subtitle {
  color: var(--dark-text-secondary);
  @include add-color-opacity(#e7e3fc, 0.68);
  font-size: 14px;
  font-weight: 400;
}

.auth-icon {
  img {
    width: 34px;
  }
}

.auth-form-field {
  mat-form-field {
    width: 100%;
    border: none;
  }
}

.auth-form-actions {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-text-secondary);
  @include add-color-opacity(#e7e3fc, 0.68);

  &--centered {
    justify-content: center;
  }
}

.auth-form-tooltip {
  font-size: 12px;
}

.auth-form-notification {
  width: 100%;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;

  &--warning {
    color: var(--warning-main);

    .auth-form-notification-container {
      border: 0.5px var(--warning-main) solid;
    }

    .auth-form-notification-text {
      color: var(--warning-main);
      @include add-color-opacity(#ffb400, 0.68);
    }
  }

  &--success {
    color: var(--success-main);

    .auth-form-notification-container {
      border: 0.5px var(--success-main) solid;
    }

    .auth-form-notification-text {
      color: var(--success-main);
      @include add-color-opacity(#56ca00, 0.68);
    }
  }
}

.auth-form-notification-container {
  align-self: stretch;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.auth-form-notification-icon {
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.auth-form-notification-text {
  flex: 1 1 0;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;

  p {
    font-size: 16px;
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: inside disc;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .auth-template {
    grid-template-columns: 58% 42%;
  }
}

@media screen and (max-width: 1024px) {
  .auth-template {
    grid-template-columns: 1fr;
  }

  .auth-background {
    display: none;
  }

  .auth-content {
    justify-content: center;

    .auth-logo {
      display: flex;
    }
  }

  .auth-logo {
    margin-top: 0;
    margin-left: 0;
    position: unset;
  }
}

@media screen and (max-width: 768px) {
  .auth-template {
    min-height: 100%;
    height: auto;
    overflow: auto;
  }

  .auth-content {
    height: auto;

    .auth-logo {
      margin-top: 20px;
    }
  }
}

// Override material styles
.auth-template {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: rgba(231, 227, 252, 0.38) !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(231, 227, 252, 0.38);
    caret-color: rgba(231, 227, 252, 0.38);
    font-size: 14px;
  }

  .mdc-text-field--outlined .mdc-text-field__input::placeholder {
    color: unset !important;
  }

  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    font-size: 12px;
    color: var(--dark-text-secondary) !important;
  }

  .mat-mdc-checkbox label {
    color: var(--dark-text-primary) !important;
  }

  input:enabled:not(:checked) ~ .mdc-checkbox__background {
    border-color: rgba(231, 227, 252, 0.38) !important;
  }

  .mat-mdc-form-field mat-icon {
    color: rgba(231, 227, 252, 0.38) !important;
  }

  .mat-icon.mat-primary {
    width: 20px;
    height: 16px;
    font-size: 16px;
    color: var(--primary-main);
  }
}

.auth-form-actions {
  .btn-primary {
    &[disabled] {
      background-color: var(--secondary-main);
      color: var(--dark-text-primary);
    }
  }
}
