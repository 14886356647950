// https://cssgurubd.wordpress.com/2019/10/22/how-to-convert-hex-to-rgba-color-by-sass/
@mixin HEXtoRGBA($property, $color, $opacity: 1) {
  #{$property}: rgba($color, $opacity);
}

@mixin add-color-opacity($color, $opacity: 1) {
  color: rgba($color, $opacity);
}

.text-center {
  text-align: center;
}

.btn-fluid {
  width: 100%;
}

.text-uppercase {
  text-transform: uppercase;
}

.btn-large {
  font-size: 15px;
  min-height: 42px;
}

.mt-5 {
  margin-top: 5px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mt-25 {
  margin-top: 25px;
}
.ml-15 {
  margin-left: 15px;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
