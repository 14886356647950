@use "@angular/material" as mat;

@include mat.core();

// You can create your own pallete from: http://mcg.mbitson.com/

$original-palette: (
  50: #e4eefa,
  100: #bbd5f2,
  200: #8dbaea,
  300: #5f9ee1,
  400: #3d89da,
  500: #01458d,
  600: #186ccf,
  700: #00254a,
  800: #1057c3,
  900: #0844b9,
  A100: #e4ecff,
  A200: #b1c7ff,
  A400: #7ea3ff,
  A700: #6491ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$gainsboro-palette: (
  50: #f9f9fa,
  100: #f3f3f4,
  200: #ebebec,
  300: #dcdcdd,
  400: #b9b9ba,
  500: #99999a,
  600: #707071,
  700: #5d5d5e,
  800: #3e3e3f,
  900: #1d1d1e,
  A100: #f5f5f5,
  A200: #eaeaeb,
  A400: #d6d6d7,
  A700: #838386,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$crimson-palette: (
  50: #ffebef,
  100: #ffcdc5,
  200: #f0999f,
  300: #e77179,
  400: #f24e58,
  500: #f83a40,
  600: #e9313e,
  700: #d72638,
  800: #ca1e30,
  900: #bb0d24,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

// Original
$original-primary: mat.m2-define-palette($original-palette, 700, 300, 900);
$original-accent: mat.m2-define-palette($gainsboro-palette, 300, 100, 500);
$original-warn: mat.m2-define-palette($crimson-palette, 700);

$original-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $original-primary,
      accent: $original-accent,
      warn: $original-warn,
    ),
  )
);

$disabled-color: #999999; // Cor para texto desabilitado
$disabled-bg-color: #f2f2f2; // Cor de fundo desabilitado

@include mat.all-component-themes($original-theme);

// Helpers, Mixins
@import "helpers";

// Theme
@import "theme";

// Shared templates
@import "auth";
@import "floater-notification";

// Tokens
@import "tokens-cards";
@import "tokens-typography";
@import "tokens-button";

// Global styles
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Figtree, "Helvetica Neue", sans-serif;
  background-color: #f5f5f5;
}
p {
  letter-spacing: 0.15px;
  word-wrap: break-word;
}

// Helper classes

// Buttons

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: var(--primary-main);
  --mdc-filled-button-label-text-color: var(--primary-contrast);

  &:hover,
  &:focus {
    background-color: var(--primary-alternative-light);
  }
}

.btn-link-primary {
  font-weight: 400;
  color: var(--primary-main);
  text-decoration: none;
  cursor: pointer;
  appearance: none;

  &:hover,
  &:focus {
    color: var(--primary-alternative-light);
  }
}

// Custom scrolbar
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c9c9c9 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px; // Vertical scroll
  height: 6px; // Horizontal scroll
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 10px;
  border: 3px solid transparent;
}

.flex-div{
    display: flex;
    flex-wrap: wrap;
}
.flex-div > div{
    flex: 1;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}
.flex-div-300{
    display: flex;
    flex-wrap: wrap;
    // gap: 2%;
}
.flex-div-300 > div,
.flex-div-300 > mat-card,
.flex-div-300 > img{
    flex: 1;
    min-width: 300px;
    // padding-left: 1.5%;
    // padding-right: 1.5%;
    padding: 1%;
  }
.flex-div-300 > mat-checkbox,
.flex-div-300 > mat-radio-button,
.flex-div-300 > mat-form-field{
    flex: 1;
    min-width: 300px;
    padding-left: 5px;
    padding-right: 5px;
}
.flex-div-300 > input,
.flex-div-300 > select{
    flex: 1;
    min-width: 300px;
    padding-left: 5px;
    padding-right: 5px;
}
.flex-div-300 > mat-checkbox,
.flex-div-300 > mat-radio-button{
    max-width: 300px;
}

.color_prymary{
  color: var(--primary-main);
}

.desktop-only {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.clickable {
  cursor: pointer;
}

.btn-primary-alternative-light {
  color: var(--primary-alternative-light) !important;
}

.btn-primary-alternative-dark {
  color: var(--primary-alternative-dark) !important;
}

.btn-highlight-main {
  color: var(--highlight-main) !important;
}


#acord-pessoa,
#acord-produto {
  .mat-expansion-panel-header {
    background-color: var(--highlight-main) !important;
    font-weight: bold !important;
  }
}

#acord-setor,
#acord-cliente {
  .mat-expansion-panel-header {
    background-color: var(--primary-main) !important;
    font-weight: bold !important;
  }
}

#acord-pessoa,
#acord-produto,
#acord-setor,
#acord-cliente {
  .mat-expansion-panel-header-title,
  .mat-expansion-indicator::after {
    color: #fff !important;
  }
}




