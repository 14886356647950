// ----------
// Cards Styles
// ----------

@mixin card {
  border-radius: 6px;
  padding: 20px;
  // border: 1px rgba(58, 53, 65, 0.266) solid;
  background-color: #fff;
  // box-shadow: 0 .275rem 1.25rem #0b0f190d, 0 .25rem .5625rem #0b0f1908;
}

.card {
  @include card;
}

// .card:hover {
//   border: 1px rgba(58, 53, 65, 0.24) solid;
// }
