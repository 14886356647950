.success-snackbar {
  background-color: green;
  font-size: 14px;
  border-radius: 4px;

  .mdc-snackbar__surface {
    background-color: transparent !important;
  }

  .mdc-snackbar__label {
    color: inherit !important;
  }
}

.error-snackbar {
  background-color: #ff4c51;
  font-size: 14px;
  border-radius: 4px;

  .mdc-snackbar__surface {
    background-color: transparent !important;
  }

  .mdc-snackbar__label {
    color: inherit !important;
  }
}
